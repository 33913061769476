<template>
  <div>
    <header class="header">
      <div class="header-text">付款给{{ payOrderInfo.mchName }}</div>
      <div class="header-img">
        <img :src="avatar ? avatar : icon_member_default" alt="" />
      </div>
    </header>
    <div class="plus-input">
      <!-- ￥字符 货币的符号-->
      <div class="S">
        <img src="../../assets/icon/S.svg" alt="" />
      </div>

      <!-- 手写输入框 -->
      <div  class="input-c">
<!--        <div  class="input-c-div-1">{{ amount }}</div>-->
        <input  ref="amount" v-model="amount"  :disabled="!isAmount" class="input-l" placeholder="请输入金额" type="number" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')"/>
      </div>
      <!-- 手写输入框的提示文字 -->
<!--      <div v-show="!amount" class="placeholder">请输入金额</div>-->
    </div>
    <ul class="plus-ul" v-show="!isAmount">
      <!-- 支付板块 -->
      <li style="border-radius:10px;">
        <!-- 支付金额板块 -->
        <div class="img-div">
          <img :src="wxImg" alt="" />
          <div class="div-text">
            云闪付支付
          </div>
        </div>
      </li>
    </ul>
    <div class="bnt-pay">
      <div
        class="bnt-pay-text"
        style="background-color:#ff534d"
        @click="pay"
      >
        付款
      </div>
    </div>
  </div>
</template>

<script>
import {getPayPackage,getPayOrderInfo}from '@/api/api'
import config from "@/config";
export default {

  data: function (){
    return {
      merchantName: '商户',  // 付款的商户默认
      avatar: require("../../assets/images/ysf.jpg"), // 商户头像默认
      amount: 1,  // 支付金额默认
      resData : {},
      wxImg: require("../../assets/images/ysf.jpg") ,// 微信支付图片
      payOrderInfo: {}, //订单信息
      isAmount: false, // 是否输入金额
    }
  },

  mounted() {
    this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件
  },

  methods: {

    setPayOrderInfo(isAutoPay){
      const that = this
      getPayOrderInfo().then(res => {
        console.log("查询订单返回数据:{}",res);
        that.payOrderInfo = res;
        if(res.payOrderId != null && res.payOrderId != undefined){
          console.log("有订单数据，进行支付:{}",res);
          that.payOrderInfo = res;
          that.amount = res.amount/100 ;
          that.isAmount = false;
          if(isAutoPay){
            that.pay()
          }
        }else{
          that.amount = "" ;
          that.isAmount = true;
          this.$refs.amount && this.$refs.amount.focus()
        }
      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },

    pay: function (){

      let that = this;
      getPayPackage(this.amount).then(res => {

        if(res.code != '0') {
          return alert(res.msg);
        }

        if(res.data.orderState != 1 ) { //订单不是支付中，说明订单异常
          return alert(res.data.errMsg);
        }

        that.resData = res.data;
        location.href = res.data.redirectUrl;
        // if (!window.AlipayJSBridge) {
        //   document.addEventListener('AlipayJSBridgeReady', function(){
        //     that.doAlipay(res.alipayTradeNo);
        //   }, false);
        // }else{
        //   that.doAlipay(res.alipayTradeNo);
        // }

      }).catch(res => {
        that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
      });
    },
    doAlipay(alipayTradeNo){
      // eslint-disable-next-line no-undef
      AlipayJSBridge.call("tradePay", {
        tradeNO: alipayTradeNo
      }, function (data) {
        if ("9000" == data.resultCode) {
          alert('支付成功！');
        }else{ //其他
        }
      });
    },

  }


}
</script>
<style lang="css" scoped>
 @import './pay.css';
</style>
